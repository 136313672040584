import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider, createTheme } from '@mui/material';

const queryClient = new QueryClient();

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "16px",
          color: "black",
          fontFamily: "Bangers",
          fontSize: "24px",
          height: "32px",
          ":disabled": {
            backgroundColor: ({ ownerState }) => ownerState.color,
            mixBlendMode: "normal",
          },
          ":hover": {
            backgroundColor: ({ ownerState }) => ownerState.color,
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#FFF',
    },
    secondary: {
      main: '#3c3c3c',
    },
    text: {
      primary: "#FFF",
    },
  },
  typography: {
    fontFamily: "Bubblegum Sans",
    h1: {
      fontFamily: "Luckiest Guy",
      fontSize: "60px",
      "@media (max-width: 600px)": {
        fontSize: "48px",
      }
    },
    h2: {
      fontFamily: "Luckiest Guy",
      fontSize: "48px",
      "@media (max-width: 600px)": {
        fontSize: "38px",
      }
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}><App /></ThemeProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
