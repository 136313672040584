import React, { useEffect, useMemo, useRef, useState } from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import validator from 'validator';
import { Typography, styled, useMediaQuery } from '@mui/material';

import './App.css';
import { useMutation } from 'react-query';
import { Box } from '@mui/material';

const INITIAL_HEADER_HEIGHT = {
  xs: 218,
  sm: 292,
};
const CENTER_HEADER_HEIGHT = {
  xs: 136,
  sm: 264,
};
const TOP_HEADER_HEIGHT = {
  xs: 200,
  sm: 132,
};

const MIN_HEADER_HEIGHT = {
  xs: 116,
  sm: 80,
}

const Page = styled(Box)(({ headerHeight }) => ({
  backgroundImage: "url('/assets/images/bg.png')",
  backgroundSize: "cover",
  backgroundPosition: "center",
  boxSizing: "border-box",
  height: "100vh",
  overflow: "hidden",
  textAlign: "center",
  width: "100vw",
  "@keyframes flash": {
    "0%, 50%, 100%": {
        opacity: "1",
    },
    "25%, 75%": {
        opacity: "0.2",
    },
}
}));

const Header = styled("header")(({ height }) => ({
  backgroundColor: "rgba(255, 255, 255, 0.8)",
  mixBlendMode: "screen",
  color: "black",
  display: "flex",
  flexDirection: height > CENTER_HEADER_HEIGHT.sm ? "column" : "row",
  left: "0",
  height,
  minHeight: "64px",
  padding: "32px",
  position: "fixed",
  top: "0",
  width: "100%",
  zIndex: "6",
  "@media (max-width: 600px)": {
    flexDirection: height > CENTER_HEADER_HEIGHT.xs ? "column" : "row",
    minHeight: "110px",
    padding: "16px",
  },
}));

const LogoTitle = styled(Box, {
  shouldForwardProp: prop => prop !== "centered" && prop !== "top"
})(({ centered, top }) => ({
  margin: centered ? "0 auto 16px" : "0",
  paddingTop: centered ? "0" : "12px",
  textTransform: "uppercase",
  width: (top && !centered) ? "auto" : "248px",
  "@media (max-width: 600px)": {
    margin: 0,
    width: top ? "auto" : "248px",
  },
}));

const InterestBox = styled(Box, {
  shouldForwardProp: prop => prop !== "centered" && prop !== "top"
})(({ centered, top }) => ({
  alignItems: "center",
  display: "flex",
  flexDirection: (top && !centered) ? "row" : "column",
  justifyContent: "center",
  margin: centered ? "0 auto" : "0",
  width: (top && !centered) ? "auto" : "248px",
  "input": {
    borderRadius: "16px",
    border: "none",
    fontFamily: 'Bubblegum Sans',
    fontSize: "24px",
    height: "32px",
    textAlign: "center",
  },
  "@media (max-width: 600px)": {
    flexDirection: centered ? "row" : "column",
    width: "100%",
  },
}));

const kitties = [
  {
    color: "rgb(129, 73, 219)",
    name: "Carmen",
    description: "Phases through danger with Carnotaurus-like stealth!",
    image: "https://res.cloudinary.com/dmyytgv2d/image/upload/v1686853650/carmen_svzx6s.png",
  },
  {
    color: "rgb(31, 113, 255)",
    name: "Ice Creamy",
    description: "Chills enemies with her Triceratops-cone blizzard!",
    image: "https://res.cloudinary.com/dmyytgv2d/image/upload/v1686853650/icecreamy_dhy3e0.png",
  },
  {
    color: "rgb(71, 227, 106)",
    name: "Coco",
    description: "Commands the battle with her Spinosaurus-tech laser!",
    image: "https://res.cloudinary.com/dmyytgv2d/image/upload/v1686853650/coco_f3jldv.png",
  },
  {
    color: "rgb(254, 134, 82)",
    name: "Meow",
    description: "Outruns trouble with Velociraptor super speed!",
    image: "https://res.cloudinary.com/dmyytgv2d/image/upload/v1686853906/meow_bbahoh.png",
  },
  {
    color: "rgb(230, 37, 37)",
    name: "Ken",
    description: "Defends the team with unbreakable Ankylosaurus armor!",
    image: "https://res.cloudinary.com/dmyytgv2d/image/upload/v1686853650/ken_npgdmo.png",
  },
]

const App = () => {
  const sectionRef = useRef(null);
  const isMobile = useMediaQuery("(max-width: 600px)")

  const initialHeaderHeight = isMobile ? INITIAL_HEADER_HEIGHT.xs : INITIAL_HEADER_HEIGHT.sm 

  const [hasSavedEmail, setHasSavedEmail] = useState();
  const [headerHeight, setHeaderHeight] = useState(initialHeaderHeight);

  const [email, setEmail] = useState("");

  const isValidEmail = useMemo(() => validator.isEmail(email), [email]);

  const { mutateAsync, isLoading } = useMutation((data) => 
    fetch('https://v3otn4bcob.execute-api.us-west-1.amazonaws.com/Production', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }),
    {
      onSuccess: () => {
        setHasSavedEmail(true);
        localStorage.setItem("dfkc__saved_email", "true");
      }
    }
  );

  const handleSubmit = async () => {
    try {
      await mutateAsync({ email });
    } catch (error) {
      console.log(error);
    }
  };

  const handleKeydown = ({key}) => {
    if (key === "Enter") {
      handleSubmit();
    }
  };

  const getRand = () => {
    return Math.floor(Math.random() * 50) + 1;
  }

  useEffect(() => {
    sectionRef.current?.addEventListener("scroll", () => {
      const scroll = sectionRef.current?.scrollTop || 0;
      const newHeight = Math.max(64, initialHeaderHeight - scroll);

      if (!scroll && (headerHeight !== initialHeaderHeight)) setHeaderHeight(initialHeaderHeight);
      else if (scroll && headerHeight !== newHeight && newHeight >= MIN_HEADER_HEIGHT[isMobile ? "xs" : "sm"]) setHeaderHeight(newHeight);
    });
  }, [headerHeight, initialHeaderHeight, isMobile])

  useEffect(() => {
    sectionRef.current.scrollTo(0,0);

    const storedEmail = localStorage.getItem("dfkc__saved_email");

    if (storedEmail) setHasSavedEmail(true);
    else setHasSavedEmail(false);
  }, [])

  return (
    <Page>
      <Header height={headerHeight}>
        <Box display="flex" height="100%" justifyContent="space-between" width="100%" maxWidth="1200px" marginX="auto" alignItems="center" sx={{ flexDirection: { xs: 'column', sm: headerHeight >= CENTER_HEADER_HEIGHT.sm ? 'column' : 'row' } }}>
          <LogoTitle top={headerHeight <= TOP_HEADER_HEIGHT[isMobile ? "xs" : "sm"]} centered={isMobile || headerHeight >= CENTER_HEADER_HEIGHT.sm}>
            <Typography variant={headerHeight <= TOP_HEADER_HEIGHT[isMobile ? "xs" : "sm"] ? "h2" : 'h1'} fontFamily="Luckiest Guy">
              Dino Fly Kitty Co
            </Typography>
          </LogoTitle>
          {hasSavedEmail ? (
            <Typography fontSize="28px">Thanks for signing up!</Typography>
          ) : (
            <InterestBox top={headerHeight <= TOP_HEADER_HEIGHT[isMobile ? "xs" : "sm"]} centered={isMobile ? headerHeight <= CENTER_HEADER_HEIGHT.xs : (headerHeight >= CENTER_HEADER_HEIGHT.sm)}>
              <input type="email" value={email} autoFocus onKeyDown={handleKeydown} onChange={event => setEmail(event.target.value)} placeholder='Enter your email!' />
              <LoadingButton width="200px" variant='contained' color='primary' loading={isLoading} onClick={handleSubmit} disabled={!isValidEmail} sx={{ mt: (isMobile ? headerHeight <= CENTER_HEADER_HEIGHT.xs : headerHeight <= TOP_HEADER_HEIGHT.sm) ? 0 : 1, ml: headerHeight <= TOP_HEADER_HEIGHT[isMobile ? "xs" : "sm"] ? 1 : 0 }}>Stay Tuned</LoadingButton>
            </InterestBox>
          )}
        </Box>
      </Header>
      <Box color="white" position="fixed" bottom="0" left="0" height={`calc(100vh - ${headerHeight}px)`} overflow="auto" ref={sectionRef} width="100%">
        <Box maxWidth="1000px" margin="0 auto" padding="32px 16px 0">
          <Typography variant='h4' mb={2} sx={{ textShadow: "0px 2px 4px black" }}>Coming soon...</Typography>
          <Typography fontSize="24px" sx={{ textShadow: "0px 2px 4px black" }}>Bring along your real-world cat to empower our adorable kitty heroes in their battle against a pesky mouse infestation. Or choose the path of chaos and join the mouse horde!</Typography>

          <Box mt={10}>
            <Typography variant='h4' fontFamily="Permanent Marker" mb={8} sx={{ textShadow: "0px 2px 4px black" }}>-- The Kitties --</Typography>

            <Box display="flex" flexWrap="wrap" gap="80px" justifyContent="center" mb={10}>
              {kitties.map(({ color, name, description, image }) => {
                return (
                <Box key={`kitty_${name}`} display="inline-flex" flexDirection="column" width="200px">
                  <Box borderRadius={`${getRand()}px ${getRand()}px ${getRand()}px ${getRand()}px`} height="200px" width="200px" backgroundColor={color} margin="0 auto" sx={{ boxShadow: "5px 5px 15px rgba(0,0,0,0.3)", transition: "100ms all"}}>
                    <img alt={name} height="100%" src={image} style={{ borderRadius: "16px", animation: name === "Carmen" ? "flash 8s infinite" : undefined }} />
                  </Box>
                  <Box zIndex="2" margin="-8px auto 16px" border={`2px solid ${color}`} backgroundColor="white" width="fit-content" borderRadius="16px" padding="0 8px">
                    <Typography fontFamily="Bangers" fontSize="28px" color="#222" sx={{ textShadow: `0px 2px 2px ${color}` }}>
                      {name}
                    </Typography>
                  </Box>
                  <Typography fontSize="24px" lineHeight="26px" sx={{ textShadow: `0px 2px 2px black` }}>{description}</Typography>
                </Box>
              )})}
            </Box>
          </Box>
        </Box>
      </Box>
    </Page>
  );
}

export default App;
